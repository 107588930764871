* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}

body {
    background-color: #c5cdd9;
}

.row {
    width: 100%;
    height: 100vh;
    display: flex;
    /* align-items: center; */
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    text-align: center;

    align-items: center;
}

.myCopy {
    margin-left: 15px;
}

.myStop {
    margin-left: 15px;
    margin-bottom: 5px;
}

.myCopy:hover {
    cursor: pointer;
}

.copied {
    position: relative;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.myText {
    width: 900px;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.searchBox {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.heightadj {
    height: 94px;
}

.txtField {
    width: 500px;
}

.title {
    position: relative;
    top: -30vh;
    font-weight: 900;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.myBtn {
    width: 130px;
    height: 60px;
}

@media (max-width: 900px) {
    input {
        width: "100px";
    }

    .myBtn {
        width: 100px;
        height: 40px;
    }

    h1 {
        font-size: 35px;
    }
}
